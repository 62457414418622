import axios from "axios";

const apiClient = {
  async login(urls, requestData) {
    const response = await axios.post(urls, requestData);
    return response.data;
  },

  async readAll(urls) {
    const response = await axios.get(urls);
    // console.log(response.data);
    return response.data;
  },
  async createUpdatedata(urls, requestData) {
    const response = await axios.post(urls, requestData);
    return response.data;
  },

  async updatedata_Put(urls, requestData) {
    console.log(urls);
    const response = await axios.put(urls, requestData, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return response.data;
  },

  async updatedata_Patch(urls, requestData) {
    const response = await axios.patch(urls, requestData);
    return response.data;
  },

  async read_Only(urls) {
    const response = await axios.get(urls);
    return response.data;
  },

  async delete_Only(urls) {
    const response = await axios.get(urls);
    return response.data;
  },
  async upload_Only(urls, requestData) {
    const response = await axios.post(urls, requestData);
    return response.data;
  }

};

export default apiClient;
