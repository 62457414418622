<template>

    <div id="app">
          <v-snackbar v-model="snackbartest.visible" auto-height :color="snackbartest.color" :multi-line="snackbartest.mode === 'multi-line'" :timeout="snackbartest.timeout" :top="snackbartest.position === 'top'">
        <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbartest.icon }}</v-icon>
            <v-layout column>
                <div>
                    <strong>{{ snackbartest.title }}</strong>
                </div>
                <div>{{ snackbartest.text }}</div>
            </v-layout>
        </v-layout>
        <v-btn v-if="snackbartest.timeout === 0" icon @click="snackbartest.visible = false">
            <v-icon>clear</v-icon>
        </v-btn>
    </v-snackbar>
        <v-row>
            <v-col   cols="7">
                <v-select
                 :items="listindicateur2"
                 v-model="selectindicateur2"
                 dense
                 outlined
                 item-value="id"
                  @change="fuseSearch"
                 label="Recherche Indicateur ODD">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.code }} - {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                     {{ data.item.code }} - {{ data.item.libelle }}
                    </template>
                 </v-select>

            </v-col>
        </v-row>
         
    
    <v-data-table
    :headers="headers"
    :items="list"
    sort-by="code"
    class="elevation-1"
  >
 
    
     <template v-slot:top>
      <v-toolbar
        flat
      >
              <v-toolbar-title>LITSE DES MODALITE</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
        
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
        </template>
        </v-snackbar>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
             @click="nouveau()" 
            >
              Nouveau
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                  >
                 <v-select
                 :items="listindicateur"
                 v-model="selectindicateur"
                 dense
                 item-value="id"
                 outlined
                 label="Indicateur ODD">
                 <template slot="selection" slot-scope="data">
                     {{ data.item.code }} - {{ data.item.libelle }}
                    </template>
                  <template slot="item" slot-scope="data">
                     {{ data.item.code }} - {{ data.item.libelle }}
                    </template>
                 </v-select>
                <v-text-field
                      v-model="libelle"
                      label="Libelle modalité *"
                      dense
                      outlined
                      required
                     
                    ></v-text-field>
                 <v-text-field
                      v-model="code_colors"
                      label="Libelle code couleur *"
                      dense
                      outlined
                      required
                     
                    ></v-text-field>
                   
                
                </v-form>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog=false"
              >
                Fermer
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Voullez vous vraiment supprimer?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete=false">Annuller</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.indicateurs`]="{ item }">
       {{ item.indicateurs.code }} - {{ item.indicateurs.libelle }}
    </template>
    
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        color="green"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        color="red"
        class="mr-2"
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Actualiser la liste
      </v-btn>
    </template>
  </v-data-table>
        

    </div>

</template>

<script>

import api from '../../../../serviceApi/apiService'

export default {
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => (
    {
        snackbartest: false,
        text: `message`,
        textsnackbar: 'messages',

       multiLine: true,
      snackbar: false,
      snackbars:false,
      text: `message`,
      textsnackbar:'messages',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false ,divider: true,width: '6%'},
        { text: 'ID', value: 'id' ,divider: true,width: '5%'},
        { text: 'MODALITE ', value: 'libelle' ,divider: true,width: '25%'},
        { text: 'LEGENDE ', value: 'code_colors' ,divider: true,width: '25%'},
        { text: 'INDICATEUR ODD', value: 'indicateurs' ,divider: true,width: '40%'},
             
      ],
      formTitle:'Formulaire de Modalité',
       list:[],
       listindicateur:[],   
       selectindicateur:'',
       listindicateur2:[],   
       selectindicateur2:'',
     
     libelle:'',
      code_colors:'',
      valider:'0',
      id:'0',
      valid: true,
      
      
      textetat:false,
      dateFormatted:null,
      messages:'',
      
      

    }),

   
  
     methods: {
      //=====Affichages  liste des odds==========
          readAll: async function() {
                const data = await api.readAll('parametre/all-modalite-odd');
                this.list = data.list;
          },

        readAllRecherche: async function() {
                const data = await api.readAll('parametre/all-modalite-odd/'+this.selectindicateur2);
                this.list = data.list;
          },

          readIndicateurAll: async function() {
                const data = await api.readAll('indicateur-odd/all');
                this.listindicateur = data.list;
                this.listindicateur2 = data.list;
                
          },
         
           fuseSearch(){
           this.readAllRecherche();
           },
          deleteData: async function() {
               let fd=new FormData();
              fd.append('id',this.id);
             const res=await api.createUpdatedata('parametre/delete',fd);   
              this.messagesligne(res);
          },

          createUpdatedata: async function() {
           
          let fd=new FormData();
          fd.append('libelle',this.libelle);
          fd.append('code_colors',this.code_colors);
          fd.append('id_indicateurodds',this.selectindicateur);
         
      
         if(this.textetat){
          
            const res=await api.createUpdatedata('parametre/update-modalite-odd/'+this.id,fd);   
        if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                    this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
            }else{
            const res=await api.createUpdatedata('parametre/add-modalite-odd',fd);    
           if (res.status == 200) {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
                   // this.dialog = false;
                } else {
                    this.textmessage = res.message;
                    this.snackbartest = {
                        color: "green",
                        icon: "mdi-check-all",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };

                }
            }
            
            this.clear();
            this.readAll();
        },
       initialize (){
         this.readAll();
       },
     
        nouveau(){
          this.id_indicateur = "0";
          this.textetat=false;
         // this.selectindicateur='0';
 
       },
      
   
    
       clear(){
         this.code_colors='';
          this.id_indicateur = "0";
          this.libelle="";
          this.textetat=false;
          this.selectindicateur='0';
          this.textetat=false;
          //this.dialog=false;
       },
      deleteItem(item){
          this.id_odds=item.id;
          this.dialogDelete=true;
         
       },
       editItem(item){
        this.id=item.id;
        this.selectindicateur=item.indicateurs.id;
        this.code_colors=item.code_colors;
        this.libelle=item.libelle;
        this.textetat=true;
        this.dialog = true;
       },

       deleteItemConfirm(){
        this.deleteData();
        this.readAll();
        this.dialogDelete=false;
       },

      

       save () {
         if(this.libelle==''){
          this.textmessage = "Veuillez remplir tous les champs";
            this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
       
           return;
         }

         if(this.code_colors==''){
             this.textmessage = "Veuillez Inserer code couleur";
            this.snackbartest = {
                        color: "red",
                        icon: "mdi-alert",
                        mode: "multi-line",
                        position: "top",
                        timeout: 2500,
                        title: "Information",
                        text: this.textmessage,
                        visible: true
                    };
           return;
         }
         this.createUpdatedata();
         
       },

         parcoure: function filtrerParID(obj) {
            if (obj.id_cibles==this.selectcible) {
              //console.log(obj.libelle);
                return true;
            } else {
               
                return false;
            }
            },
           
     
   
        },
   mounted() {
        this.readAll();
        this.readIndicateurAll();
        //this.readindicateurAll();
      }, 
 
 
}
</script>
